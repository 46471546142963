[
   {
      "image": "./assets/passive.png",
      "icon-text": "Passive Income Potential",
      "icon": "./assets/svgs/passive.svg",
      "title": "Automatically and strategically promoting products or services.",
      "description": "You will never miss anything important \n when you're working with Premium Digital Affiliate Software. It utilizes the promoting products through affiliate links, individuals earn commissions on sales. Once set up, affiliate link generate income without the need for continuous active involvement. Successful affiliate marketers can build a sustainable income stream, allowing for flexibility and financial growth over time. even with reduced day-to-day hands-on involvement."
   },
   {
      "image": "./assets/affiliate.png",
      "icon-text": "Affiliate Marketing",
      "icon": "./assets/svgs/affiliate.svg",
      "title": "Save resources with promotional materials",
      "description": "Save money on a wide range of promotional materials with Premium Digital affiliate's popular features. You can obtain multiple types of banners and smart affiliate links for adverts to highlight your online presence and create traffic to boost your sales and leads. It's easy to do even for new users, and it takes just a moment to finish."
   },
   {
      "image": "./assets/low.png",
      "icon-text": "Low entry barrier",
      "icon": "./assets/svgs/low.svg",
      "title": "Low cost and low risk",
      "description": "The beauty of Premium Digital Affiliate program is that it has a remarkably low barrier. There is no need to create your own products or inventory and it allows individuals to enter the field with minimal upfront costs. This low barrier makes it accessible to a wide range of investors, regardless of their financial ability, educational background or previous experience. The low costs are reflected in the fact that there are no product creation or inventory costs. The low risk is evident in the performance-based compensation structure. Affiliatestypically earn high revenue from sales or leads generated, which translates into minimal or no financial risk."
   },
   {
      "image": "./assets/automated.png",
      "icon-text": "Automated workflow",
      "icon": "./assets/svgs/automated.svg",
      "title": "Automatically and strategically promoting prcxiucts or services.",
      "description": "You will never miss anything important when you're working with Premium Digital Affiliate Software, It utilizes the most accurate.way of promoting products through affiliate links, individuals earn commissions on sales. Once set up, affiliate link generate without the need for continuous active involvement Successful affiliate marketers can build a sustainable income stream, allowing for flexibility and financial growth over time, even with reduced day-to-day hands-on involvement,"
   },
   {
      "image": "./assets/multiple.png",
      "icon-text": "Multiple income streams",
      "icon": "./assets/svgs/multiple.svg",
      "title": "Diversification",
      "description": "Premium Digital Affiliate enables investors to broaden their income streams and mitigate the risks associated with relying on a single source Of revenue. By engaging with multiple affiliate programs, investors can create a more resilient and stable passive income portfolio."
   }
]
